import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Cosmetics() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          Cosmetics <span>and Skincare </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Cosmetics and Skincare</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
                  Cosmetics <span>and Skincare </span>

            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/cosmetics.jpg"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    {companyname} specializes in providing temperature-sensitive and controlled supply chain solutions specifically designed for makeup and skincare companies. We understand the unique requirements of the cosmetics and skincare industry, and our multi-step logistics routine ensures seamless management of both retailer requirements and e-commerce demands.

                    <br/>Our forward-thinking strategies, meticulous planning, and timely deliveries are always aligned with the dynamic priorities of the ever-evolving cosmetics and skincare industry. With expertise in cosmetics warehousing, we are equipped to handle high volumes of products and navigate the complexities of temperature and handling restrictions, licenses, and regulations while ensuring on-time delivery at a competitive cost.

                    <br/>To thrive in the current business climate, cosmetics logistics managers require efficient solutions to address changing customer priorities and expedite new product releases. At {companyname}, we offer tech-enabled third-party logistics (3PL) services, inventory management, retail and distribution support, packaging solutions, and e-commerce capabilities.

                    <br/>Given the occasional fluctuations in customer demands driven by media recommendations, the distribution challenges in the cosmetics industry often lead to SKU proliferation and compliance issues related to labeling and temperature control. Additionally, shipment sizes may vary from one location to another. To tackle these challenges effectively, our reverse logistics solutions provide real-time visibility throughout the value chain, encompassing warehousing, order management, and transportation.

                    <br/>With {companyname} as your logistics partner, you can rely on our comprehensive services and expertise to overcome the complexities of the cosmetics and skincare industry. We are dedicated to ensuring that your products are handled and delivered with the utmost care, meeting the demands of both retailers and online customers.



                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
