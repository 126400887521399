
import { Outlet, Link } from "react-router-dom";
import {
	companyname,
	companyurl,
	companyemail,
	companyaddress,
	companynumber,
  } from "./Companyinfo";
  

const Footer = () => {
  return (
    <>
     	 {/* footer */} 
	<div class="footer">
		<div class="footer_agile_inner_info_w3l">
			<div class="col-md-3 footer-left">
			<Link to="/home">
                <img src="images/logo.png" alt="" style={{ width: "140px" }} />
              </Link>
				<p> At {companyname}, our mission is to bridge the gap between
              digitization and personalized customer service for international
              shippers. As a digital forwarding partner, we combine cutting-edge
              technology with dedicated support. </p>
				
			</div>
			<div class="col-md-6 footer-right">
				<div class="sign-grds">
					<div class="col-md-4 sign-gd">
						<h4>Useful <span>Links</span> </h4>
						<ul>
						<li>
                            <Link to="/Home">Home</Link>
                          </li>
						<li>
                            <Link to="/whoweare">Who We Are</Link>
                          </li>
                          <li>
                            <Link to="/whyus">Why Us</Link>
                          </li>
                          <li>
                            <Link to="/missionvision">Mission-Vision</Link>
                          </li>
						  <li>
                            <Link to="/contact">Contact Us</Link>
                          </li>
                          <li>
                            <Link to="/careers">Careers</Link>
                          </li>
                          <li>
                            <Link to="/getquote">Getquote</Link>
                          </li>
						</ul>
					</div>
					<div class="col-md-4 sign-gd">
						<h4>Our <span>Services</span> </h4>
						<ul>
						<li>
                            <Link to="/ocean">Ocean Freight</Link>
                          </li>
                          <li>
                            <Link to="/air">Air Freight</Link>
                          </li>
                          <li>
                            <Link to="/rail">Rail Freight</Link>
                          </li>
                          <li>
                            <Link to="/road">Road Freight</Link>
                          </li>
                          <li>
                            <Link to="/warehousing">Warehousing</Link>
                          </li>
                          <li>
                            <Link to="/custom">Customs Clearance</Link>
                          </li>
						</ul>
					</div>
					<div class="col-md-4 sign-gd">
						<h4>Industries <span></span> </h4>
						<ul>
						<li>
                            <Link to="/Automotive">Automotive</Link>
                          </li>
                          <li>
                            <Link to="/Apparel">Apparel &amp; Lifestyle</Link>
                          </li>
                          <li>
                            <Link to="/Cosmetics">
                              Cosmetics &amp; Skincare
                            </Link>
                          </li>
                          <li>
                            <Link to="/Food">Food &amp; Beverages</Link>
                          </li>
                          <li>
                            <Link to="/Pharma">Pharma &amp; Healthcare</Link>
                          </li>
                          <li>
                            <Link to="/Chemical">Chemical &amp; Oils</Link>
                          </li>
						</ul>
					</div>

					
					
				</div>
			</div>
			<div class="sign-grds">
				

					<div class="col-md-3 sign-gd-two">
						<h4>Contact <span>Us </span></h4>
						<div class="w3-address">
							<div class="w3-address-grid">
								<div class="w3-address-left">
									<i class="fa fa-phone" aria-hidden="true"></i>
								</div>
								<div class="w3-address-right">
									<h6>Phone Number</h6>
									<p>{companynumber}</p>
								</div>
								<div class="clearfix"> </div>
							</div>
							<div class="w3-address-grid">
								<div class="w3-address-left">
									<i class="fa fa-envelope" aria-hidden="true"></i>
								</div>
								<div class="w3-address-right">
									<h6>Email Address</h6>
									<p>{companyemail}</p>
								</div>
								<div class="clearfix"> </div>
							</div>
							<div class="w3-address-grid">
								<div class="w3-address-left">
									<i class="fa fa-map-marker" aria-hidden="true"></i>
								</div>
								<div class="w3-address-right">
									<h6>Location</h6>
									<p>{companyaddress}

									</p>
								</div>
								<div class="clearfix"> </div>
							</div>
						</div>
					</div>
					
				</div>
			<div class="clearfix"></div>
			
			<p class="copy-right">©2023 All rights reserved | <Link
					to="/Home">{companyname}</Link></p>
		</div>
	</div>
	 {/* //footer */} 

	 {/* login */} 
	<div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
		<div class="modal-dialog" role="document">
			<div class="modal-content modal-info">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
							aria-hidden="true">&times;</span></button>
				</div>
				<div class="modal-body modal-spa">
					<div class="login-grids">
						<div class="login">
							<div class="login-bottom">
								<h3>Sign up for free</h3>
								<form>
									<div class="sign-up">
										<h4>Email :</h4>
										<input type="text" value="Type here" onfocus="this.value = '';"
											onblur="if (this.value == '') {this.value = 'Type here';}" required=""/>
									</div>
									<div class="sign-up">
										<h4>Password :</h4>
										<input type="password" value="Password" onfocus="this.value = '';"
											onblur="if (this.value == '') {this.value = 'Password';}" required=""/>

									</div>
									<div class="sign-up">
										<h4>Re-type Password :</h4>
										<input type="password" value="Password" onfocus="this.value = '';"
											onblur="if (this.value == '') {this.value = 'Password';}" required=""/>

									</div>
									<div class="sign-up">
										<input type="submit" value="REGISTER NOW"/>
									</div>

								</form>
							</div>
							<div class="login-right">
								<h3>Sign in with your account</h3>
								<form>
									<div class="sign-in">
										<h4>Email :</h4>
										<input type="text" value="Type here" onfocus="this.value = '';"
											onblur="if (this.value == '') {this.value = 'Type here';}" required=""/>
									</div>
									<div class="sign-in">
										<h4>Password :</h4>
										<input type="password" value="Password" onfocus="this.value = '';"
											onblur="if (this.value == '') {this.value = 'Password';}" required=""/>
										<a href="a">Forgot password?</a>
									</div>
									<div class="single-bottom">
										<input type="checkbox" id="brand" value=""/>
										<label for="brand"><span></span>Remember Me.</label>
									</div>
									<div class="sign-in">
										<input type="submit" value="SIGNIN"/>
									</div>
								</form>
							</div>
							<div class="clearfix"></div>
						</div>
						<p>By logging in you agree to our <a href="a">Terms and Conditions</a> and <a href="a">Privacy
								Policy</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
	 {/* //login */} 
	<a href="ahome" class="scroll" id="toTop" > <span id="toTopHover" >
		</span></a>

      <Outlet />
    </>
  )
};

export default Footer;