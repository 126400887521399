import Header from '../components/Header'
import Footer from '../components/Footer'

import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';



export default function custom(){
    return(
        <>
        <Header/>
 
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Customs <span>Clearance </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Customs Clearance</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Customs <span>Clearance</span></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/custom.jpeg" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>
                {companyname} is well-versed in the intricacies of managing cross-border consignments. Their
                comprehensive customs brokerage, clearance, and compliance services combine extensive expertise in
                global customs regulations with local knowledge, ensuring the smooth and efficient flow of international
                shipments to and from any location worldwide. <br />
                The experienced team at {companyname} handles a significant number of customs entries annually and manages all
                aspects of customs processes, including import and export clearance, declarations, Performa invoices,
                and certificates of origin. Their expertise in the field encompasses various areas such as customs
                regulations, bond requirements, duty rates, as well as petition and protest procedures. <br /> <br />
                The customs clearance services provided by {companyname} cover a range of import and export
                consignments and include the following:<br />
                1. Guidance and consultancy on pre and post-shipment services: {companyname} offers valuable advice and
                support regarding the necessary procedures and requirements before and after the shipment of goods.<br />
                2. Liasoning and follow-up with various government organizations: The company assists in coordinating
                and maintaining effective communication with relevant government entities on behalf of clients to ensure
                compliance and timely processing.<br />
                3. Documentation procedures: {companyname} takes care of the preparation and handling of all necessary
                documents, ensuring accuracy and adherence to customs regulations.<br />
                4. Post-shipment formalities and endorsements: The company manages all the required formalities and
                endorsements that need to be completed after the shipment has been sent, ensuring compliance with
                relevant regulations.<br />
                5. Drawback/DEPB and export benefits: {companyname} assists clients in understanding and leveraging
                export benefits such as drawback and Duty Entitlement Passbook (DEPB), maximizing their potential
                advantages in international trade.<br />
                With their comprehensive customs clearance services, {companyname} aims to simplify the customs
                processes, provide guidance and support, and help clients navigate the complexities of international
                trade while ensuring compliance with customs regulations.
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
     
        
        <Footer/>
        </>
    )
}