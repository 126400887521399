import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />

      <div>
        {/* banner */}
        <section className="video-container">
        <video autoPlay loop muted>
          <source src="images/morphic.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> 
        <div className="text-overlay ab_pic_w3ls_text_info" style={{width:'100%',height:'100%',background:'#00000052',paddingTop:'180px'}}>
          <h3 className="wthree_text_info" style={{color:'white', marginBottom:'0'}}>
            <span>Welcome to</span> {companyname}
            <br/>

          </h3>
          <p>Efficient Freight Forwarding for Seamless Global Trade</p>
          <a className="hvr-outline-out button2" href="/whweare" style={{color: '#fff', letterSpacing: '3px', padding: '8px 20px', marginTop: '2em'}}>
            Lean More
          </a>

          


        </div>
      </section>
        
        
        
        
       
      <div className="banner_bottom_agile_info">
        <div className="container">
          <div className="agile_ab_w3ls_info">
            <div
              className="col-md-6 ab_pic_w3ls"
              style={{ paddingTop: "20px" }}
            >
              <img
                src="images/whoweare.jpg"
                alt=" "
                className="img-responsive"
              />
            </div>
            <div className="col-md-6 ab_pic_w3ls_text_info">
              <h5>
                About <span> {companyname}</span>{" "}
              </h5>
              <p>
                As a forward-thinking freight forwarder, we are dedicated to
                delivering tailored, comprehensive, and high-value solutions for
                our clients' supply chains, all while fostering enduring
                relationships built on trust and confidence. We handle the
                demanding tasks, allowing our clients to relax and unwind.{" "}
                <br />
                We firmly believe that exceptional freight services should be
                accessible to all. With our skilled and talented team, we are
                fully committed to providing unparalleled service that surpasses
                market standards.
                <br />
                <a className="hvr-outline-out button2" href="/whweare" style={{color: '#fff', letterSpacing: '3px', padding: '8px 20px', marginTop: '2em'}}>
            Lean More
          </a>
              </p>
            </div>
            <div className="clearfix" />
          </div>

          <h3 className="wthree_text_info">
            Our <span>Services</span>
          </h3>
          <div className="banner_bottom_agile_info_inner_w3ls">
         
          <Link to="/ocean">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/ocean.jpg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   Ocean <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/air">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/air.jpg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}
                />
                <figcaption>
                  <h3>
                   Air <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/rail">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/rail.png"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   Rail <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <div className="clearfix" />
          </div>
        </div>
        <div className="container">
          <div className="agile_ab_w3ls_info">
          
            <div className="col-md-6 ab_pic_w3ls_text_info">
            
            </div>
            <div className="clearfix" />
          </div>
          <div className="banner_bottom_agile_info_inner_w3ls">
          <Link to="/road">

<div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
  <figure className="effect-roxy">
    <img
      src="images/road.jpg"
      alt=" "
      className="img-responsive"
      style={{height:'250px'}}

    />
    <figcaption>
      <h3>
       Road <span>Freight</span>
      </h3>
      
    </figcaption>
  </figure>
</div>
</Link>
          <Link to="/warehousing">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/warehousing.png"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   W<span>arehousing</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/custom">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/custom.jpeg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}
                />
                <figcaption>
                  <h3>
                   Customs <span>Clearance</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            
            <div className="clearfix" />
          </div>
        </div>
      </div>
      </div>
      <div className="schedule-bottom">
        <div className="col-md-6 agileinfo_schedule_bottom_left">
          <img
            src="images/whyus.jpg"
            alt=" "
            className="img-responsive"
            style={{  position: "relative" }}
          />
        </div>
        <div className="col-md-6 agileits_schedule_bottom_right">
          <div className="w3ls_schedule_bottom_right_grid">
            <h3>
              Why <span>Us</span>
            </h3>
            <p>
              The international logistics industry, despite its crucial role in
              facilitating global trade, has been lacking in innovation and
              improvement for an extended period. Outdated and traditional
              methods continue to dominate shipping and logistics operations,
              leading to inefficiencies and errors. This results in lengthy
              quotation, documentation, and booking processes, hindering
              companies seeking international shipping. The lack of transparency
              in shipping activities and the complexity arising from multiple
              intermediaries further compounds the challenges faced by shippers.
             
            </p>
            <a className="hvr-outline-out button2" href="/whyus" style={{color: '#fff', letterSpacing: '3px', padding: '8px 20px', marginTop: '2em'}}>
            Lean More
          </a>

            <div className="clearfix"> </div>
          </div>
        </div>
        <div className="clearfix"> </div>
      </div>
      <div className="banner_bottom_agile_info team">
        <div className="container">
          <h3 className="wthree_text_info">
            Our Industry <span>Solutions</span>
          </h3>
          <div className="inner_w3l_agile_grids">
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/automative.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Automotive">
                    <h4>Automotive</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/apparel.webp")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Apparel">
                    <h4>Apparel &amp; Lifestyle</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/cosmetics.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Cosmetics">
                    <h4>Cosmetics &amp; Skincare</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            
           
            <div className="clearfix"> </div>
          </div>
        </div>
        <div className="container">
        
          <div className="inner_w3l_agile_grids">
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/food.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Food">
                    <h4>Food &amp; Beverages</h4></Link >
                  </div>
                  
                </div>
              </div>
           </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/pharma.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Pharma">
                    <h4>Pharma &amp; Healthcare</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/chemical.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Chemical">
                    <h4>Chemical &amp; Oils</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            
            
           
            <div className="clearfix"> </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
