import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Food() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          Food <span>and Beverages </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Food and Beverages</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
                  Food <span>and Beverages </span>

            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/food.jpg"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    At {companyname}, we have developed a robust Contract logistics model specifically designed to tackle the significant challenges faced by the Food and beverage industry. With the industry grappling with fluctuating commodity prices, intricate manufacturing constraints, increasing demand for organic products, heightened emphasis on hygiene, traceability, and time management, it is clear that numerous obstacles need to be overcome on a daily basis.

<br/>As a performance-driven supply chain service provider, {companyname} is dedicated to offering comprehensive strategic planning and management services tailored specifically for the Food and beverage industry. Our solutions encompass the entire supply chain, encompassing warehousing, fulfillment, distribution, packaging, kitting, reverse logistics, and transportation. By providing end-to-end support, we empower companies to effectively navigate the hurdles of a challenging market and achieve positive outcomes.
                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
