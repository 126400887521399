import { useState } from "react";
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";


export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
   const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  }; 
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  }; 

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Header />

      <div>
        {/* /banner_bottom_agile_info */}
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>
              C<span>ontact Us </span>
            </h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li>
                    <Link to="/Home">Home</Link>
                    <i>|</i>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/*/contact*/}
        <div className="banner_bottom_agile_info">
          <div className="container">
            <div className="contact-grid-agile-w3s">
              <div className="col-md-4 contact-grid-agile-w3">
                <div className="contact-grid-agile-w31">
                  <i className="fa fa-map-marker" aria-hidden="true" />
                  <h4>Address</h4>
                  <p>{companyaddress}</p>
                </div>
              </div>
              <div className="col-md-4 contact-grid-agile-w3">
                <div className="contact-grid-agile-w32">
                  <i className="fa fa-phone" aria-hidden="true" />
                  <h4>Call Us</h4>
                  <p>{companynumber}</p>
                </div>
              </div>
              <div className="col-md-4 contact-grid-agile-w3">
                <div className="contact-grid-agile-w33">
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                  <h4>Email</h4>
                  <p>{companyemail}</p>
                </div>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>
        </div>
        <div className="contact-w3-agile1 map" data-aos="flip-right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.9083181313786!2d73.01500707224848!3d19.023761214227456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3bdd58924c9%3A0x123982a5f3399097!2s19a%2C%20Seawoods%20Bridge%2C%20Seawoods%20East%2C%20Nerul%20East%2C%20Sector%2019A%2C%20Nerul%2C%20Navi%20Mumbai%2C%20Maharashtra%20400706!5e0!3m2!1sen!2sin!4v1686121857951!5m2!1sen!2sin"
            width="100%"
            height={450}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="banner_bottom_agile_info">
          <div className="container">
            <div className="agile-contact-grids">
              <div className="agile-contact-left">
                <div className="col-md-6 address-grid">
                  <h4>
                    For More <span>Information</span>
                  </h4>
                  <div className="mail-agileits-w3layouts">
                    <i
                      className="fa fa-volume-control-phone"
                      aria-hidden="true"
                    />
                    <div className="contact-right">
                      <p>Telephone </p>
                      <span>{companynumber}</span>
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                  <div className="mail-agileits-w3layouts">
                    <i className="fa fa-envelope-o" aria-hidden="true" />
                    <div className="contact-right">
                      <p>Mail </p>
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                  <div className="mail-agileits-w3layouts">
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    <div className="contact-right">
                      <p>Location</p>
                      <span>{companyaddress}</span>
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                 
                </div>
                <div className="col-md-6 contact-form">
                  <h4 className="white-w3ls">
                    Contact <span>Form</span>
                  </h4>
                  <div className="App">
                    <form
                      action="/php/thankyou-contact.php"
                      method="post"
                      onSubmit={(event) => handleSubmit(event)}
                    >

                      <div className="styled-input agile-styled-input-top">
                        <input   onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                        <span />
                      </div>
                      <div className=" styled-input">
                        <input   onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                      <div className="styled-input">
                        
                        <input   onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                      <div className="styled-input">
                        <input   onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                      <div className="styled-input">
                        <textarea
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                      <input type="submit" name="submit" defaultValue="SEND" />
                      <br />
                    </form>
                    <h1>{result}</h1>
                  </div>
                </div>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>
        </div>
        {/*//contact*/}
       
      </div>

      <Footer />
    </>
  );
}
