import Header from '../components/Header'
import Footer from '../components/Footer'

import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';



export default function air(){
    return(
        <>
        <Header/>
        
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Air <span>Freight </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Air Freight</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Air <span>Freight</span></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/air.jpg" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>
                {companyname} offers comprehensive global logistics services through air freight, providing a seamless
                and worry-free experience for businesses. The company specializes in International Freight Services,
                including International Freight Forwarding and Air Freight Forwarding, facilitating import and export
                activities between India and major world markets. Whether for domestic or international shipments, {companyname} ensures efficient connections with fixed schedules for air charters, offering customers
                flexibility and convenience. Their clearance services are designed to expedite customs procedures,
                ensuring smooth and hassle-free shipment handling.<br />
                With an extensive transportation and distribution network, {companyname} simplifies the customer's
                supply chain and significantly reduces freight shipment complexities. Customers benefit from instant
                access to detailed shipment and transportation information, including duties, export transactions,
                transportation costs, and important dates. By implementing advanced transportation and logistics
                technology, {companyname} enhances supply chain visibility, flexibility, and profitability for their
                clients.<br />
                Service Highlights provided by {companyname} include time-definite express service, charter or
                part-charter options, consolidation, and direct-to-consignee service. The company can accommodate
                special cargoes such as refrigerated goods, offering door-to-door and airport-to-airport pricing options
                to meet diverse customer needs.<br />
                In addition to their standard air freight services, {companyname} provides customizable solutions
                tailored to specific requirements. These options include handling high-value cargo (HVC), managing
                dangerous goods (DGR), ensuring the safe transportation of shock-sensitive cargo, offering
                temperature-controlled transportation, providing cargo insurance, processing letter of credit (LC)
                requirements, handling certification and legalization of documents, facilitating Food and Drug
                Administration (FDA) filings, and fulfilling Advanced Cargo Information (ACI) filing requirements.
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
    
        
        <Footer/>
        </>
    )
}