
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";



export default function Careers(){
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
   const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  }; 
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  }; 
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  }; 

  
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };




 
  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
    return(
        <>
        <Header/>
         
        {/*?php include('header.php'); ?*/}
        {/*?php include('companyinfo.php'); ?*/}
        {/* /banner_bottom_agile_info */}
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>C<span>areers </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Careers</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        <div className="banner_bottom_agile_info">
          <div className="container">
            <div className="agile-contact-grids">
              <div className="agile-contact-left">
                <div className="col-md-12 contact-form">
                  <h4 className="white-w3ls">Contact <span>Form</span></h4>
                  <form
                      action="/php/thankyou-careers.php"
                      method="post"
                      onSubmit={(event) => handleSubmit(event)}
                    >
                    <div className="row p-2">
                      <div className="col-md-4">
                        <div className="form-floating">
                          <input onChange={(event) => handlefullnameChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={fullname} id="fullname" name="fullname" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating">
                          <input onChange={(event) => handleemailidChange(event)} type="email" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={emailid} id="emailid" name="emailid" placeholder="Your Email" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating">
                          <input onChange={(event) => handlephoneChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={phone} id="handlephoneChange" name="phone" placeholder="Phone Number" />
                        </div>
                      </div>
                    </div>
                    <label>Highest Qualification</label>
                    <div className="col-12 p-2">
                      <div className="form-floating">
                        <input onChange={(event) => handlequalificationChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={qualification} id="qualification" name="qualification" placeholder="qualification" required />
                      </div>
                    </div>
                    <label>Position Applied for</label>
                    <div className="col-12 p-2">
                      <div className="form-floating">
                        <input onChange={(event) => handlepositionChange(event)} type="text" style={{width: '100%', padding: '10px', margin: '6px 0'}} value={position} id="position" name="position" placeholder="position" required />
                      </div>
                    </div>
                    <label>Add cover letter</label>
                    <div className="col-12 p-2">
                      <div className="form-floating">
                        <textarea onChange={(event) => handlecoverletterChange(event)} style={{width: '100%', padding: '10px', margin: '6px 0'}} placeholder="Leave a message here" value={coverletter} id="coverletter" name="coverletter" required defaultValue={""} />
                      </div>
                    </div>
                 
                    <div>
                      <input type="submit" name="submitcareers" id="submitcareers" defaultValue="Apply" />
                    </div>
                  </form>
                  <h1>{result}</h1>

                </div>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>
        </div>
        {/*//contact*/}
        {/*?php include('footer.php'); ?*/}
      
        
        <Footer/>
        </>
    )
}