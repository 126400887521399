
import Header from '../components/Header'
import Footer from '../components/Footer'

import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';



export default function ocean(){
    return(
        <>
        <Header/>

        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Ocean <span>Freight </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Ocean Freight</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Ocean <span>Freight</span></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/ocean.jpg" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>{companyname} offers a comprehensive ocean freight program that focuses on connecting major
                world markets, particularly the traffic lanes between North America, Asia, and Europe. The company has
                established contracts with over a dozen ocean carriers for trans-Pacific and trans-Atlantic services,
                providing clients with cost savings and a wide selection of weekly sailing sea carriers. <br /><br />
                The service highlights of {companyname} include:<br />
                1. Full-container-load (FCL): This option is suitable for customers who have enough cargo to fill an
                entire container.<br />
                2. Less-than-container-load (LCL): Customers with smaller shipments can opt for LCL, where their goods
                are consolidated with shipments from other customers to fill a container.<br />
                3. Dry or liquid bulk shipments: {companyname} handles both dry and liquid bulk shipments to
                accommodate various types of cargo.<br />
                4. Full or partial vessel charters: The company can arrange for full or partial vessel charters,
                including specialized services such as refrigerated service or on-board cranes for handling heavy
                shipments to smaller ports.<br />
                5. Physical cargo and paper-consolidation programs: {companyname} provides consolidation
                programs for importers and exporters who purchase goods from multiple vendors. This service streamlines
                the import/export process by consolidating physical cargo and managing necessary paperwork.<br /><br />
                In addition to these services, {companyname} offers various value-added options, including:<br />
                - Dangerous Goods Handling (DGR): Handling and transporting goods that are classified as dangerous or
                hazardous.<br />
                - Temperature Controlled Transportation: Managing shipments that require specific temperature control,
                such as perishable goods.<br />
                - Cargo Insurance: Providing insurance coverage for shipments to protect against loss or damage during
                transit.<br />
                - Letter of Credit Processing (LC): Assisting customers with the processing of letters of credit, which
                are commonly used in international trade transactions.<br />
                - Certification and Legalisation of Documents: Handling the necessary certifications and legalizations
                for international trade documents, ensuring compliance with regulations and requirements.<br />
                - Food and Drug Administration Filing (FDA): Assisting with the filing of necessary documentation for
                shipments that involve food or drugs regulated by the FDA.<br />
                - Advanced Cargo Information Filing (ACI): Managing the filing of advanced cargo information to comply
                with customs regulations and expedite customs clearance.<br /><br />
                Customer benefits provided by {companyname} include:<br />
                - Complete schedule flexibility: Clients can choose from a range of transit times and scheduled sailing
                options to meet their specific requirements.<br />
                - Defined transit time statement: {companyname} provides clear statements regarding the
                expected transit time for shipments, allowing customers to plan accordingly.<br />
                - Preparation of necessary declarations and reporting: The company takes care of all the required
                declarations and reporting to the relevant authorities, simplifying the shipping process for customers.<br />
                - Full shipment tracking visibility: Clients have access to comprehensive shipment tracking, allowing
                them to monitor the progress of their shipments in real-time.<br /><br />
                Overall, {companyname} offers competitive rates, a wide range of services, and value-added
                options to cater to the diverse needs of its customers in the international freight forwarding and air
                freight forwarding sectors.<br />
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
    
        <Footer/>
        </>
    )
}