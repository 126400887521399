import Header from '../components/Header'
import Footer from '../components/Footer'

import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';



export default function rail(){
    return(
        <>
        <Header/>
       
        {/* /banner_bottom_agile_info */}
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Rail <span>Freight </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Rail Freight</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Rail <span>Freight</span></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/rail.png" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>
                Railway Freight is a highly dependable and efficient method for transporting valuable cargo quickly and
                economically. <br />
                {companyname} stands out as a premier rail cargo service and management solution provider in
                India. With our reliability and extensive experience in railway logistics, we offer an effective
                solution for transporting freight along the railway line. Our expert handling ensures timely delivery of
                wagons to their intended destinations. As a trusted rail transport services provider, we have gained a
                reputation for our in-depth knowledge and proficiency in transporting goods by rail. We prioritize
                customer satisfaction and provide superior care services, catering to various shifting requirements
                while minimizing stress and inconvenience.<br />
                We offer dedicated space in VPs (Parcel Vans) and SLRs (Special Class Railway Wagons) on major trunk
                routes as well as non-trunk routes, ensuring our esteemed customers have reliable access to
                transportation options.<br />
                Our track record demonstrates excellence in the safe and secure movement of shipments.
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
      
        
        <Footer/>
        </>
    )
}