import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet, Link } from "react-router-dom";


import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';



export default function road(){
    return(
        <>
        <Header/>
       
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Road <span>Freight </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Road Freight</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Road <span>Freight</span></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/road.jpg" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>
                {companyname} offers a wide range of customized and cost-effective road transportation
                solutions for freight delivery across India. With an extensive network, efficient cargo movement
                facilities, and client-oriented services, the company has become a preferred distribution solution
                provider in the country. They have a diverse fleet of transport carriers and can schedule inter-state
                deliveries to meet specific time requirements. The company's experts are experienced in supporting
                customers' supply chain needs, handling general commodity freight as well as specialized shipments. They
                have containerized trucks available for transporting perishable goods and other sensitive consignments.
                Each container undergoes rigorous testing, including humidity, leakage, and floor checks, ensuring that
                only containers meeting the highest standards are loaded and transported. <br />
                Trucking logistics involves the research and planning of trucking operations to maximize productivity
                and efficiency. It encompasses factors such as optimizing truck routes, selecting fuel types based on
                market trends, choosing the most suitable trucks for specific tasks, and hiring skilled truck drivers.<br />
                In many industrialized nations, trucks play a vital role in commercial transportation and the
                distribution of goods. Effective trucking logistics not only contributes to the success of individual
                companies but also benefits the overall economy of a nation. Transportation is often a significant
                expense for businesses, and efficient logistics can greatly reduce economic costs. By eliminating
                unnecessary expenditures and streamlining operations, good planning can enhance the financial
                performance of businesses.<br />
                Trucking logistics also focuses on analyzing trucking routes to improve efficiency and reduce delivery
                times. The nature of the cargo being transported is carefully considered to determine the most suitable
                transportation methods. Road safety is a key priority, ensuring the secure transportation of goods and
                the safety of truck drivers and other road users. Delivery routes are planned based on the specific
                types of loads being carried. Heavy hauling and long-haul transportation are more complex aspects of
                trucking, and thorough trucking logistics examines all factors to facilitate these challenging transport
                operations.
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
     
        
        <Footer/>
        </>
    )
}