import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pharma() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          Pharma <span>& HealthCare </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Pharma & HealthCare</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
                  Pharma <span>& HealthCare </span>

            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/pharma.jpg"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    At {companyname}, our Pharma & Healthcare services are driven by a strong sense of responsibility towards individuals in need of pharmaceutical products, medicines, herbal remedies, or medical equipment. We prioritize proper sanitation and safety measures, ensuring controlled temperatures within our warehouses, preventing product shortages, and guaranteeing timely delivery of life-saving medications. Recognizing the criticality of maintaining product integrity, we adhere to the regulatory requirements of national markets, acting as a specialized partner.

                  <br/>  {companyname} comprehends the importance of fostering a comprehensive and meaningful collaboration among patients, regulators, and retailers to facilitate the efficient functioning of the pharmaceutical industry. We offer tailored logistic and supply chain solutions, along with specialized warehousing services, to pharmaceutical companies of all types. This enables them to swiftly achieve compliance, meet stringent regulatory deadlines, and confidently thrive in a competitive market while minimizing risks and maximizing operating margins. We hold the utmost regard for life-saving entities, and thus, we provide timely, premium, and transparent supply chain solutions that empower them to better serve society and the nation.
                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
