import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Apparel() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          Apparel <span> and Lifestyle </span>

          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>              Apparel and Lifestyle
</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
              Apparel <span> and Lifestyle </span>
            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/apparel.webp"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    {companyname} offers highly optimized warehouse and logistics services tailored specifically for apparel, fashion, uniforms, lifestyle products, and the associated e-commerce and retail distribution sector. Our solutions are at the forefront of industry technology and digital advancements. Given the exponential growth projected for online retail in India, internet sales on various marketplaces have become a priority for apparel and lifestyle brands.

                    <br/>As the online retail market continues to expand rapidly, companies in the clothing and lifestyle sector face the dual challenge of capitalizing on growth opportunities while maintaining efficiency, quality, and speed to meet the diverse demands of end-users.

                    <br/>At {companyname}, we recognize the importance of timely response to rapidly changing fashion trends. As the fastest-growing logistics service provider for the retail and e-commerce sector in India, we are dedicated to delivering comprehensive logistics solutions and operating clothing distribution warehouses for beauty and lifestyle brands. We understand the dynamic challenges that companies encounter in the logistics and supply chain vertical, and we leverage tools, technology, and expertise to effectively address these challenges.

                    <br/>Our aim is to support clothing and lifestyle businesses in navigating the complexities of their supply chain, enabling them to adapt quickly to market trends, optimize operational efficiency, and deliver exceptional customer experiences. By providing end-to-end logistics services and leveraging our industry knowledge, we help our clients thrive in the competitive retail and e-commerce landscape.

                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
