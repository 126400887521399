import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function missionvision() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
            Our<span> Misson-Vision</span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Our Misson-Vision</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      
      
      {/*/grids*/}
      <div className="coupons">
        <div className="coupons-grids text-center">
          <div className="w3layouts_mail_grid">
            <div className="col-md-6 w3layouts_mail_grid_left">
              <div className="w3layouts_mail_grid_left1 hvr-radial-out">
                <i className="fa fa-bullseye" aria-hidden="true" />
              </div>
              <div className="w3layouts_mail_grid_left2">
                <h3>Our Mission</h3>
                <p>
                  As seasoned logistics professionals, we recognize that
                  providing adaptable and tailored solutions for ever-changing
                  supply chains is crucial to our customers' success. <br />
                  Drawing on our extensive international expertise, local market
                  knowledge, and forward-thinking approach, we prioritize the
                  development of robust, long-term partnerships with our
                  customers. We stand by their side, assisting them in tackling
                  their supply chain challenges head-on and identifying avenues
                  for growth. <br />
                  By closely examining the evolving needs of our customers'
                  supply chains, we meticulously craft logistics solutions that
                  precisely align with their requirements. <br />
                  Our support extends to companies of all sizes and across
                  diverse sectors, including Automotive, Consumer & Retail,
                  Energy, Healthcare, Industrial & Aerospace, and Technology.{" "}
                  <br />
                  Furthermore, we leverage our CAR software to enhance
                  efficiency and streamline operations, ensuring optimal
                  performance throughout the logistics process.
                </p>
              </div>
            </div>
            <div className="col-md-6 w3layouts_mail_grid_left">
              <div className="w3layouts_mail_grid_left1 hvr-radial-out">
                <i className="fa fa-eye" aria-hidden="true" />
              </div>
              <div className="w3layouts_mail_grid_left2">
                <h3>Our Vision</h3>
                <p>
                  Logistics serves as the fundamental pillar of international
                  trade and holds immense significance in every facet of our
                  daily routines.
                  <br />
                  Keeping this in mind, our goal is to establish CEVA Logistics
                  as an unrivaled global leader in the logistics sector. By
                  leveraging our expertise and capabilities, we empower
                  businesses to expand their horizons and foster prosperous
                  communities. <br />
                  At the heart of our operations lie the requirements of our
                  customers and our strategic vision. In a world characterized
                  by growing unpredictability, constant change, intricate
                  complexities, and seemingly contradictory factors, we strive
                  to generate value for our customers. We achieve this by
                  consistently providing exceptional end-to-end supply chain
                  solutions that cater to their most intricate and demanding
                  needs.
                </p>
              </div>
            </div>

            <div className="clearfix"> </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
