import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <div>
        {/* header */}
        <div className="header" id="home">
          <div />
        </div>
        {/* //header */}
        {/* header-bot */}
        <div
          className="header-bot header"
          style={{ backgroundColor: "#fff", border: "solid 2px #009846" }}
        >
          <div className="header-bot_inner_wthreeinfo_header_mid">
            <div className="col-md-5 header-middle ">
              <ul style={{ float: "left" }}>
                <li
                  style={{
                    width: "220px",
                    color: "black",
                    position: "relative",
                    border: "none",
                    top: "30px",
                  }}
                >
                  <i className="fa fa-phone" aria-hidden="true" />
                  {companynumber}
                </li>
                <li
                  style={{
                    width: "220px",
                    color: "black",
                    position: "relative",
                    border: "none",
                    top: "30px",
                  }}
                >
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                  {companyemail}
                </li>
              </ul>
            </div>
            {/* header-bot */}
            <div className="col-md-2 logo_agile">
              <Link to="/home">
                <img src="images/logo.png" alt="" style={{ width: "140px" }} />
              </Link>
            </div>
            {/* header-bot */}
            <div className="col-md-5 header-middle">
              <ul style={{ float: "right" }}>
                <li
                  style={{
                    width: "220px",
                    color: "black",
                    position: "relative",
                    border: "none",
                    top: "30px",
                  }}
                >
                  {" "}
                  <Link style={{ color: "black" }} to="/privacypolicy">
                    Privacy Policy
                  </Link>
                </li>
                <li
                  style={{
                    width: "220px",
                    color: "black",
                    position: "relative",
                    border: "none",
                    top: "30px",
                  }}
                >
                  {" "}
                  <Link style={{ color: "black" }} to="/terms">
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        {/* //header-bot */}
        {/* banner */}
        <div className="ban-top">
          <div className="container" style={{ width: "1200px" }}>
            <div className="top_nav_left">
              <nav className="navbar navbar-default">
                <div className="container-fluid">
                  {/* Brand and toggle get grouped for better mobile display */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  {/* Collect the nav links, forms, and other content for toggling */}
                  <div
                    className="collapse navbar-collapse menu--shylock"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul className="nav navbar-nav menu__list">
                      <li className="active menu__item menu__item--current">
                        <Link className="menu__link" to="/">
                          Home <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li className="menu__item dropdown">
                        <Link className="menu__link" data-toggle="dropdown">
                          Company profile <b className="caret" />
                        </Link>
                        <ul className="dropdown-menu agile_short_dropdown">
                          <li>
                            <Link to="/whoweare">Who We Are</Link>
                          </li>
                          <li>
                            <Link to="/whyus">Why Us</Link>
                          </li>
                          <li>
                            <Link to="/missionvision">Mission-Vision</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu__item dropdown">
                        <Link className="menu__link" data-toggle="dropdown">
                          Services
                          <b className="caret" />
                        </Link>
                        <ul className="dropdown-menu agile_short_dropdown">
                          <li>
                            <Link to="/ocean">Ocean Freight</Link>
                          </li>
                          <li>
                            <Link to="/air">Air Freight</Link>
                          </li>
                          <li>
                            <Link to="/rail">Rail Freight</Link>
                          </li>
                          <li>
                            <Link to="/road">Road Freight</Link>
                          </li>
                          <li>
                            <Link to="/warehousing">Warehousing</Link>
                          </li>
                          <li>
                            <Link to="/custom">Customs Clearance</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu__item dropdown">
                        <Link className="menu__link" data-toggle="dropdown">
                          Industry Solutions <b className="caret" />
                        </Link>
                        <ul
                          className="dropdown-menu agile_short_dropdown"
                          style={{ padding: "10px" }}
                        >
                          <li>
                            <Link to="/Automotive">Automotive</Link>
                          </li>
                          <li>
                            <Link to="/Apparel">Apparel &amp; Lifestyle</Link>
                          </li>
                          <li>
                            <Link to="/Cosmetics">
                              Cosmetics &amp; Skincare
                            </Link>
                          </li>
                          <li>
                            <Link to="/Food">Food &amp; Beverages</Link>
                          </li>
                          <li>
                            <Link to="/Pharma">Pharma &amp; Healthcare</Link>
                          </li>
                          <li>
                            <Link to="/Chemical">Chemical &amp; Oils</Link>
                          </li>
                        </ul>
                      </li>
                      <li className=" menu__item">
                        <Link className="menu__link" to="/Careers">
                          Careers
                        </Link>
                      </li>
                      <li className=" menu__item">
                        <Link className="menu__link" to="/contact">
                          Contact
                        </Link>
                      </li>
                      <li className=" menu__item box_1">
                        <Link
                          className="menu__link"
                          to="/getquote"
                          style={{ padding: "10px" }}
                        >
                          Request For Quote
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className=" top_nav_right"></div>
          </div>
        </div>
        {/* //banner-top */}
      </div>

      <Outlet />
    </>
  );
};

export default Header;
