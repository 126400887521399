import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet, Link } from "react-router-dom";


import { companyname, companyurl, companyemail,companyaddress,companynumber } from '../components/Companyinfo';


export default function warehousing(){
    return(
        <>
        <Header/>
       
   
        <div className="page-head_agile_info_w3l">
          <div className="container">
            <h3>Warehousing <span> </span></h3>
            {/*/w3_short*/}
            <div className="services-breadcrumb">
              <div className="agile_inner_breadcrumb">
                <ul className="w3_short">
                  <li><Link to="/Home">Home</Link><i>|</i></li>
                  <li>Warehousing</li>
                </ul>
              </div>
            </div>
            {/*//w3_short*/}
          </div>
        </div>
        {/* banner-bootom-w3-agileits */}
        <div className="banner-bootom-w3-agileits">
          <div className="container">
            <div className="resp-tabs-container">
              {/*/tab_one*/}
              <h3 className="wthree_text_info">Warehousing <span /></h3>
              <div className="-text-center" style={{padding: '10px'}}>
                <img src="images/warehousing.png" alt="" style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
              </div>
              <p style={{padding: '20px'}}>
                Effective logistics and supply chain management play a vital role in business operations. {companyname}
                Pvt. Ltd. recognizes the importance of these key functions and offers strategic warehousing solutions to
                its customers. <br />
                {companyname}'s warehouses are strategically located in close proximity to International
                Airports. They are well-designed, staffed with experienced personnel, and equipped with state-of-the-art
                inventory management, security, and specialized storage systems. The facilities boast modern storage
                systems and efficient material handling equipment, ensuring high levels of operational efficiency for
                their clientele.<br /><br />
                Customer Benefits:<br />
                The flexible warehousing services provided by {companyname} offer the following advantages to
                customers:<br />
                1. Concentration on core competencies: By outsourcing warehousing functions to {companyname}, customers
                can focus on their core business activities, allowing for greater efficiency and productivity.<br />
                2. Increased competitive flexibility and working capital release: Flexible warehousing services enable
                customers to adapt quickly to changing market demands, resulting in greater competitive flexibility.
                Additionally, by utilizing external warehousing services, customers can free up working capital for
                other business needs.<br />
                3. Lean and agile operations: By leveraging the expertise and scale efficiencies of logistics companies
                like {companyname}, customers can achieve lean and agile operations, optimizing their supply chain processes and
                reducing inefficiencies.<br />
                4. Access to logistics companies' expertise and scale efficiencies: Partnering with {companyname}
                provides customers with the advantage of leveraging the knowledge and efficiency of a specialized
                logistics provider, leading to improved operations and cost optimization.<br />
                {companyname} has branches in major metro cities and mini metros across India, allowing them
                to offer storage locations nationwide. This widespread presence enables the company to serve multiple
                clients and provide customer-friendly solutions. Some of the customer benefits include flexible hiring
                options, utilization of modern racking and handling equipment, reduced material breakage and damage,
                engineering solutions for customized warehouse design and construction, and the implementation of
                warehouse management systems for efficient inventory control.<br />
                By availing {companyname}' warehousing services, customers can streamline their operations, improve
                inventory management, and benefit from the expertise and resources of a trusted logistics partner.
              </p>
              {/*//tab_one*/}
            </div>
            {/*/slider_owl*/}
          </div>
        </div>
      
        
        <Footer/>
        </>
    )
}