import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Chemical() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          Chemicals <span>& Oils </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Chemicals & Oils</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
                  Chemicals <span>& Oils </span>

            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/chemical.jpg"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    The chemical industry relies heavily on a safe and reliable logistics system, and at {companyname}, we understand its significance. Our Chemical logistics services are designed to be responsive and adaptable, ensuring we can effectively meet the unique requirements of the industry. We pride ourselves on providing innovative solutions that allow us to rapidly and efficiently respond to market changes.

<br/>With our team of experts, we offer specialized support for your Chemical and oils business, specifically in DG (Dangerous Goods) warehouse and logistics operations. As a global industry leader in logistics, we bring extensive knowledge and experience to ensure that your chemical business receives the necessary services, regardless of the challenges it may present. We offer both part load and full truckload services to cater to your specific needs.

<br/><br/>Our offerings for the Chemical and Oil industries include:<br/>
- Warehouse facilities equipped with fire safety equipment to ensure the utmost safety.<br/>
- Comprehensive certification and management of handling equipment, ensuring compliance and reliability.<br/>
- Local training and certification services to enhance the competence of your workforce.<br/>
- Qualified logistics personnel with the expertise to handle your projects efficiently and effectively.
<br/><br/>
At {companyname}, we are committed to providing reliable and tailored logistics solutions that support the smooth operations of the chemical industry, enabling you to focus on your core business with confidence.
                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
