import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Automotive() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
          AUTOMOTIVE <span> </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>AUTOMOTIVE</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* banner-bootom-w3-agileits */}
      <div className="banner-bootom-w3-agileits">
        <div className="container">
          
          <div className="col-md-8 single-right-left simpleCart_shelfItem">
            
            
            
            
           
           
          </div>
          <div className="clearfix"> </div>
          {/* /new_arrivals */}
          <div className="responsive_tabs_agileits">
            <div id="horizontalTab">
              
              <div className="resp-tabs-container">
                {/*/tab_one*/}
                <div className="tab1">
                  <div className="single_page_agile_its_w3ls">
                  <h3 className="wthree_text_info">
              Automotive <span> </span>
            </h3>
            <div className="container" style={{width:'80%',padding: '20px', justifyContent: 'center'}}>
        <img className="image" src="images/automative.jpg"  style={{maxWidth: '-webkit-fill-available', minWidth: '-webkit-fill-available'}} />
      </div>

           
                    <p>
                    {companyname} Express is committed to delivering exceptional service levels (SLAs) and expedited solutions for aircraft, space, and defense aviation parts. Our advanced Warehouse Management System (WMS) and parts management system provide detailed and transparent online track and trace capabilities, facilitating streamlined billing and efficient warehouse operations.

<br/>We offer a comprehensive range of high-quality warehouse management services, including Vendor-Managed Inventory (VMI), kitting service, just-in-time delivery, and in-plant logistics. By adhering to international standards, we cater to the automotive industry nationwide in India. Our logistics solutions are strategically designed to meet the evolving needs of OEM manufacturers and automotive parts manufacturers, including those involved in high-end production such as Tier 1 and Tier 2 suppliers.

<br/>To support sustained growth in the automotive industry, we implement rigorous Quality, Cost, and Delivery (QCD) improvement activities. Our logistics team operates with a focus on perfection across all functional levels, employing error-proofing measures in core processes. We excel in delivering automotive parts at the right time and location (just-in-time), managing inventories effectively, handling recalls, and tracking expansions while ensuring compliance.

<br/>Additionally, we provide warehousing and transportation services for supplementary materials, including temperature-controlled and hazardous items. Our comprehensive solutions help contain costs, address evolving customer demands with confidence, manage risks affordably, and enhance overall visibility.

<br/>As the fastest-growing supply chain company, we strive to be the leader in the competitive Indian aviation market, continually raising the bar for excellence in service and performance.


                    </p>
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* //new_arrivals */}
         
        </div>
      </div>
      <Footer />
    </>
  );
}
