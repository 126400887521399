import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whyus() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
            Why <span>Us </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Why Us</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* /banner_bottom_agile_info */}
      
      <div className="schedule-bottom">
        <div className="col-md-6 agileinfo_schedule_bottom_left">
          <img
            src="images/whyus.jpg"
            alt=" "
            className="img-responsive"
            style={{ top: "130px", position: "relative" }}
          />
        </div>
        <div className="col-md-6 agileits_schedule_bottom_right">
          <div className="w3ls_schedule_bottom_right_grid">
            <h3>
              Why <span>Us</span>
            </h3>
            <p>
              The international logistics industry, despite its crucial role in
              facilitating global trade, has been lacking in innovation and
              improvement for an extended period. Outdated and traditional
              methods continue to dominate shipping and logistics operations,
              leading to inefficiencies and errors. This results in lengthy
              quotation, documentation, and booking processes, hindering
              companies seeking international shipping. The lack of transparency
              in shipping activities and the complexity arising from multiple
              intermediaries further compounds the challenges faced by shippers.
              <br />
              At {companyname}, our mission is to bridge the gap between
              digitization and personalized customer service for international
              shippers. As a digital forwarding partner, we combine cutting-edge
              technology with dedicated support. Our team comprises logistics
              experts and innovators who are driving transformative changes in
              international maritime logistics. We prioritize transparency,
              simplicity, and service excellence to revolutionize the industry.
            </p>

            <div className="clearfix"> </div>
          </div>
        </div>
        <div className="clearfix"> </div>
      </div>
      <div className="banner_bottom_agile_info team">
        <div className="container">
          <h3 className="wthree_text_info">
            Our Industry <span>Solutions</span>
          </h3>
          <div className="inner_w3l_agile_grids">
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/automative.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Automotive">
                    <h4>Automotive</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/apparel.webp")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Apparel">
                    <h4>Apparel &amp; Lifestyle</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/cosmetics.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Cosmetics">
                    <h4>Cosmetics &amp; Skincare</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            
           
            <div className="clearfix"> </div>
          </div>
        </div>
        <div className="container">
        
          <div className="inner_w3l_agile_grids">
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/food.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Food">
                    <h4>Food &amp; Beverages</h4></Link >
                  </div>
                  
                </div>
              </div>
           </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/pharma.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Pharma">
                    <h4>Pharma &amp; Healthcare</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-4 team-grids">
              <div className="thumbnail team-w3agile">
              <div style={{height: '200px', backgroundImage: 'url("images/chemical.jpg")', backgroundSize: 'cover'}} />
                <div className="social-icons team-icons right-w3l fotw33">
                 <div className="caption"><Link to="/Chemical">
                    <h4>Chemical &amp; Oils</h4></Link >
                  </div>
                  
                </div>
              </div>
            </div>
            
            
           
            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
       

      <Footer />
    </>
  );
}
