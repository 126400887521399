import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Ocean from "./pages/ocean";
import Rail from "./pages/rail";
import Air from "./pages/air";
import Road from "./pages/road";
import Warehousing from "./pages/warehousing";
import Custom from "./pages/custom";
import Automotive from "./pages/Automotive";
import Apparel from "./pages/Apparel";
import Cosmetics from "./pages/Cosmetics";
import Food from "./pages/Food";
import Pharma from "./pages/Pharma";
import Chemical from "./pages/Chemical";
import Careers from "./pages/Careers";
import Getquote from "./pages/getquote";
import Whoweare from "./pages/whoweare";
import Whyus from "./pages/whyus";
import Privacypolicy from "./pages/privacypolicy";
import Terms from "./pages/Terms";

import Missionvision from "./pages/missionvision";
import Signin from "./admin/Signin";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />

          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ocean" element={<Ocean />} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Custom" element={<Custom />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Apparel" element={<Apparel />} />
          <Route path="/Cosmetics" element={<Cosmetics />} />
          <Route path="/Food" element={<Food />} />
          <Route path="/Pharma" element={<Pharma />} />
          <Route path="/Chemical" element={<Chemical />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Whoweare" element={<Whoweare />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Missionvision" element={<Missionvision />} />
          <Route path="/Signin" element={<Signin />} />
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));