import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";


import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whoweare() {
  return (
    <>
      <Header />

      <div className="page-head_agile_info_w3l">
        <div className="container">
          <h3>
            Who <span>We Are </span>
          </h3>
          {/*/w3_short*/}
          <div className="services-breadcrumb">
            <div className="agile_inner_breadcrumb">
              <ul className="w3_short">
                <li>
                  <Link to="/Home">Home</Link>
                  <i>|</i>
                </li>
                <li>Who We Are</li>
              </ul>
            </div>
          </div>
          {/*//w3_short*/}
        </div>
      </div>
      {/* /banner_bottom_agile_info */}
      <div className="banner_bottom_agile_info">
        <div className="container">
          <div className="agile_ab_w3ls_info">
            <div
              className="col-md-6 ab_pic_w3ls"
              style={{ paddingTop: "20px" }}
            >
              <img
                src="images/whoweare.jpg"
                alt=" "
                className="img-responsive"
              />
            </div>
            <div className="col-md-6 ab_pic_w3ls_text_info">
              <h5>
                About <span> {companyname}</span>{" "}
              </h5>
              <p>
                As a forward-thinking freight forwarder, we are dedicated to
                delivering tailored, comprehensive, and high-value solutions for
                our clients' supply chains, all while fostering enduring
                relationships built on trust and confidence. We handle the
                demanding tasks, allowing our clients to relax and unwind.{" "}
                <br />
                We firmly believe that exceptional freight services should be
                accessible to all. With our skilled and talented team, we are
                fully committed to providing unparalleled service that surpasses
                market standards.
                <br />
                Through close collaboration with our valued clients, we ensure
                seamless and efficient progress of their freight, while
                simultaneously preserving and enhancing their profitability.
                Together, we strive to make the journey of their cargo smooth
                and successful.
              </p>
            </div>
            <div className="clearfix" />
          </div>

          <h3 className="wthree_text_info">
            Our <span>Services</span>
          </h3>
          <div className="banner_bottom_agile_info_inner_w3ls">
         
          <Link to="/ocean">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/ocean.jpg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   Ocean <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/air">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/air.jpg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}
                />
                <figcaption>
                  <h3>
                   Air <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/rail">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/rail.png"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   Rail <span>Freight</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <div className="clearfix" />
          </div>
        </div>
        <div className="container">
          <div className="agile_ab_w3ls_info">
          
            <div className="col-md-6 ab_pic_w3ls_text_info">
            
            </div>
            <div className="clearfix" />
          </div>
          <div className="banner_bottom_agile_info_inner_w3ls">
          <Link to="/road">

<div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
  <figure className="effect-roxy">
    <img
      src="images/road.jpg"
      alt=" "
      className="img-responsive"
      style={{height:'250px'}}

    />
    <figcaption>
      <h3>
       Road <span>Freight</span>
      </h3>
      
    </figcaption>
  </figure>
</div>
</Link>
          <Link to="/warehousing">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/warehousing.png"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}

                />
                <figcaption>
                  <h3>
                   W<span>arehousing</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            <Link to="/custom">

            <div className="col-md-4 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/custom.jpeg"
                  alt=" "
                  className="img-responsive"
                  style={{height:'250px'}}
                />
                <figcaption>
                  <h3>
                   Customs <span>Clearance</span>
                  </h3>
                  
                </figcaption>
              </figure>
            </div>
            </Link>
            
            <div className="clearfix" />
          </div>
        </div>
      </div>
      {/* team */}
     

      <Footer />
    </>
  );
}
